/*****************************************************************************************
 * 설명 : 이메일 로그인
 * URI : /login/email
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Button from '@mui/material/Button';
import { CheckBoxEx, InputEx } from 'components/inputEx';
import { useFormik } from 'formik';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { showDialog } from 'reducers/dialogReducer';
import { setUserInfo } from 'reducers/userReducer';

import { Restful } from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import '../member.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const LoginEmail = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const history = useNavigate();

  const [queryString, setQueryString] = useState({});

  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
      saveId: ''
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().required('필수'),
      password: Yup.string().required('필수'),
    }),
    onSubmit: (values) => {
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setLogin',
        ...values
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          dispatch(showDialog({header: '안내', message: response.data.message}));
          if( (response.data.data?.di ?? '') === 'no' ) {
            history('/login/certificate', {
              state: {
                userId: response.data.data.id
              }
            });

          } else {


            window.localStorage.setItem("sajuin_token", response.data.token );

            // 아이디 저장
            if (values.saveId)
              cookies.set('SAJUIN_ID', values.adminId);
            else
              cookies.remove('SAJUIN_ID');

            if( response.data.data && response.data.data.length > 0 )
              dispatch(setUserInfo(response.data.data[0]));
            else
              dispatch(setUserInfo({}));

            returnUrl();
          }

        } else {
          dispatch(showDialog({header: '안내', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 로그인 후 리턴 처리
  ***************************************************************************************/
  const returnUrl = () => {
    if( (queryString?.returnUrl ?? '') === '' )
      history('/');
    else if( queryString.returnUrl === undefined )
      history('/');
    else {
      history(queryString?.returnUrl);
    }
  }

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

    const id = cookies.get('SAJUIN_ID');

    if( id !== undefined && id !== 'undefined' ) {
      formik.setFieldValue('adminId', id);
      formik.setFieldValue('saveId', true);
    }

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <form className="member-home" onSubmit={formik.handleSubmit}>
        <h1 className="page-title login-title">이메일 로그인</h1>

        <div className="login-wrap">
          <InputEx
            name="userId"
            formik={formik}
            fullWidth={true}
            className="inputbox"
            variant="outlined"
            placeholder="이메일(아이디)"
            autoFocus
          />
          <NavLink to="/find/email" className="info-txt1">이메일을 잊어버리셨나요?</NavLink>

          <InputEx
            type="password"
            name="password"
            formik={formik}
            fullWidth={true}
            variant="outlined"
            className="inputbox"
            placeholder="비밀번호"
          />
          <NavLink to="/pw/reset" className="info-txt1">비밀번호를 잊어버리셨나요?</NavLink>

          <CheckBoxEx
            name="keepLogin"
            color="success"
            formik={formik}
            className="check"
            label="로그인 상태 유지"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />

          <Button
            type="submit"
            variant="contained"
            className="login-btn green mt30"
          >로그인</Button>

          <NavLink to="/join" className="info-txt">회원가입 하기 &gt;&gt;</NavLink>
        </div>
      </form>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default LoginEmail;