/*****************************************************************************************
 * 설명 : 로그인
 * URI : /login
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useGoogleLogin } from '@react-oauth/google';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { Restful } from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import { showDialog } from 'reducers/dialogReducer';
import { setUserInfo } from 'reducers/userReducer';

import '../member.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Login = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryString, setQueryString] = useState({});

  // 도메인 가져오기
  const url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
  const [facebookAppId, setFacebookAppId] = useState('');
  const [googleClientId, setGoogleClientId] = useState('965824537912-dg485pfli45774fgg8h8sq63fj0ivvl6.apps.googleusercontent.com');

  /***************************************************************************************
   * 설명 : CSRF를 위한 랜덤 문자열 생성
  ***************************************************************************************/
  const generateRandomState = () => {
    return Math.random().toString(36).substring(7); // 랜덤한 문자열 생성
  };

  /***************************************************************************************
   * 설명 : 로그인 후 리턴 처리
  ***************************************************************************************/
  const returnUrl = () => {
    if( (queryString?.returnUrl ?? '') === '' )
      navigate('/');
    else if( queryString.returnUrl === undefined )
      navigate('/');
    else
      navigate(queryString?.returnUrl);
  }

  /***************************************************************************************
   * 설명 : 네이버 로그인 연동
  ***************************************************************************************/
  const handleNaverLogin = async () => {
    try {
      let clientId = '';

      // localhost sajuin.o2oz.net
      if( window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('sajuin.o2oz.net') > -1 ) {
        clientId = 'RKY689LiesJZrYIb6Cqg';

      // 운영서버
      } else {
        clientId = 'OY7_07lmyLn_uo9sSGMW';

      }

      let uri = '';
      if( (queryString?.returnUrl ?? '') === '' ) uri = '';
      else if( queryString.returnUrl === undefined ) uri = '';
      else uri = '?returnUrl=' + queryString?.returnUrl;

      const redirectUri = encodeURIComponent( url + '/callbacks/naver' + uri); // 콜백 URL 설정
      const state = generateRandomState(); // CSRF 방지를 위한 랜덤한 문자열
      const naverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
      window.open(naverAuthUrl, 'naverLogin', '');
    } catch (error) {
      console.error('Error occurred during Naver login:', error);
    }
  };

  /***************************************************************************************
   * 설명 : 카카오 연동 회원정보 가져와서 처리
  ***************************************************************************************/
  const getKakaoLoginUserInfo = (user) => {

    let name = ( (user?.properties ?? '') !== '' ) ? user?.properties?.nickname : '';

    let params = {
      program: 'api',
      service: 'callback',
      action: 'getKakaoLoginUserInfo',
      version: '1.0',
      id: user?.id,
      name: name,
      ...user
    }
    getApi("get", params).then( response => {

      // 이미 가입된 회원인 경우
      if( response !== undefined && response.data.result && response.data.data ) {
        dispatch(setUserInfo(response.data?.data));

        window.localStorage.setItem("sajuin_token", response.data.token );

        if( parseInt(response.data.isNew) === 0) {
          // 이름 미기록 회원의 경우
          if ( (response.data?.data?.name ?? '') === '' )  {
            const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);
            dispatch(showDialog({header: '안내', message: '프로필 입력을 완료해주세요. 지금 프로필을 입력해두시면 서비스를 이용하실 때 생년월일시 등이 자동으로 입력되어 더욱 편리하게 서비스를 이용하실 수 있습니다.', click: () => navigate('/join/profile?sso=kk&id=' + user?.id + uri)}));

          } else {
            returnUrl();
          }

        } else {
          const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);
          navigate('/join/profile?sso=kk&id=' + user?.id + uri);
        }

      // 처음 회원인 경우 - 추가정보 입력이 필요할 경우 변경 처리
      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 페이스북 연동 회원정보 가져와서 처리
  ***************************************************************************************/
  const getFacebookLoginUserInfo = (user) => {
    let params = {
      program: 'api',
      service: 'callback',
      action: 'getFacebookLoginUserInfo',
      version: '1.0',
      ...user
    }

    getApi("get", params).then( response => {
      // 이미 가입된 회원인 경우
      if( response !== undefined && response.data.result && response.data.data ) {
        dispatch(setUserInfo(response.data?.data));

        window.localStorage.setItem("sajuin_token", response.data.token );
        if( parseInt(response.data.isNew) === 0) {
          // 이름 미기록 회원의 경우
          if( (response.data?.data?.name ?? '') === '' ) {
            const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);

            dispatch(showDialog({header: '안내', message: '프로필 입력을 완료해주세요. 지금 프로필을 입력해두시면 서비스를 이용하실 때 생년월일시 등이 자동으로 입력되어 더욱 편리하게 서비스를 이용하실 수 있습니다.', click: () => navigate('/join/profile?sso=fb&id=' + user?.id + uri)}));

          } else {
            returnUrl();
          }

        } else {
          const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);

          navigate('/join/profile?sso=fb&id=' + response.data.data?.eid + uri);
        }

      // 처음 회원인 경우 - 추가정보 입력이 필요할 경우 변경 처리
      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  const handleKakaoLogout = () => {
    if (window.Kakao.Auth) {
      window.Kakao.Auth.logout(() => {
        console.log('Kakao logout complete.');
      });
    }
  };

  /***************************************************************************************
   * 설명 : 카카오 로그인 연동
  ***************************************************************************************/
  const handleKakaoLogin = () => {
    // 기존 로그아웃 처리
    handleKakaoLogout();

    window.Kakao.Auth.login({
      success: function(authObj) {
        // 사용자 정보 요청
        window.Kakao.API.request({
          url: '/v2/user/me',
          success: function(response) {

            setUserInfo(response);

            getKakaoLoginUserInfo(response);


          },
          fail: function(error) {
            console.error(error);
          },
        });
      },
      fail: function(err) {
        console.error(err);
      },
    });
  };

  /***************************************************************************************
   * 설명 : 구글 연동 회원정보 가져오기
  ***************************************************************************************/
  const getGoogleLoginUserInfo = (accessToken) => {
    let params = {
      program: 'api',
      service: 'callback',
      action: 'getGoogleLoginUserInfo',
      version: '1.0',
      accessToken: accessToken
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data ) {

        let tmp = {...response.data.data};

        tmp.id = tmp.id.replace(/people\//gi, '');

        // 로그인 처리
        getGoogleLogin(tmp);

      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 가져온 구글 연동 회원정보로 로그인 처리
  ***************************************************************************************/
  const getGoogleLogin = (user) => {
    let params = {
      program: 'api',
      service: 'callback',
      action: 'getGoogleLogin',
      version: '1.0',
      ...user
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data ) {
        dispatch(setUserInfo(response.data?.data));

        window.localStorage.setItem("sajuin_token", response.data.token );

        if( parseInt(response.data.isNew) === 0) {
          // 이름 또는 생년월일  미기록 회원의 경우
          if (((response.data?.data?.name ?? '') === '' ) || ((response.data?.data?.sjms_year ?? '') === '' )
                || ((response.data?.data?.sjms_month ?? '') === '' ) || ((response.data?.data?.sjms_day ?? '') === '' )
              ) {
            const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);

            dispatch(showDialog({header: '안내', message: '프로필 입력을 완료해주세요. 지금 프로필을 입력해두시면 서비스를 이용하실 때 생년월일시 등이 자동으로 입력되어 더욱 편리하게 서비스를 이용하실 수 있습니다.', click: () => navigate('/join/profile?sso=gg&id=' + response.data?.data?.eid + uri)}));

          } else {
            returnUrl();
          }

        } else {
          const uri = '&name=' + encodeURIComponent(response.data.data?.name) + '&email=' + encodeURIComponent(response.data.data?.email);
          navigate('/join/profile?sso=gg&id='+ response.data?.data?.eid + uri);
        }

      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 구글 연동 처리
  ***************************************************************************************/
  const onSuccess = async (response) => {
    // 액세스 토큰을 사용하여 사용자 정보 가져오기
    getGoogleLoginUserInfo(response.access_token);
  };

  const onFailure = (response) => {
    console.error(response);
  };

  // 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
  const handleGoogleLogin = useGoogleLogin({
    googleClientId,
    onSuccess,
    onFailure,
    cookiePolicy: 'single_host_origin',
    scope: 'profile email',
    redirectUri: 'https://www.sajuin.com/login' // 여기에 맞는 리디렉션 URI 설정
  });

  /***************************************************************************************
   * 설명 : 페이스북 연동 처리
  ***************************************************************************************/
  const handleResponse = (response) => {
    // 로그인 성공 시 처리
    if( response.profile !== undefined ) {
      getFacebookLoginUserInfo(response.profile);
    }
  };

  /***************************************************************************************
   * 설명 : 초기 처리
  ***************************************************************************************/
  useEffect(() => {

    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

    /***************************************************************************************
     * 페이스북, 구글, 아이디 설정
    ***************************************************************************************/
    if( window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('sajuin.o2oz.net') > -1 ) {
      setFacebookAppId('799662218892435');
      setGoogleClientId('965824537912-dg485pfli45774fgg8h8sq63fj0ivvl6.apps.googleusercontent.com');

    } else {
      setFacebookAppId('539550833585988');
      setGoogleClientId('32601286824-4r4msf4jc7o7avvuijhr70audlporp3t.apps.googleusercontent.com');
    }

    /***************************************************************************************
     * 네이버 콜백 처리 시작
    ***************************************************************************************/
    const handleMessage = (event) => {

       // 수신된 메시지의 타입 확인
      if (event.data && event.data?.type === 'naverLogin') {

        dispatch(setUserInfo(event.data?.data));
        // 새로운 URL 주소를 받아와서 사용
        const newURL = event.data.url;

        // 부모 창의 URL 업데이트
        // navigate(newURL);
        setTimeout(() => {

             if (event.data?.data !== undefined) {
            // 이름 미기록 회원의 경우
            if  (((event.data?.data?.name ?? '') === '' ) || ((event.data?.data?.sjms_year ?? '') === '' )
              || ((event.data?.data?.sjms_month ?? '') === '' ) || ((event.data?.data?.sjms_day ?? '') === '' )
            ) {
              const uri = '&name=' + encodeURIComponent(event.data?.data?.name) + '&email=' + encodeURIComponent(event.data?.data?.email);

              dispatch(showDialog({header: '안내', message: '프로필 입력을 완료해주세요. 지금 프로필을 입력해두시면 서비스를 이용하실 때 생년월일시 등이 자동으로 입력되어 더욱 편리하게 서비스를 이용하실 수 있습니다.', click: () => navigate('/join/profile?sso=nv&id='+ event.data?.data?.eid + uri)}));

            } else {
              window.location.href = newURL;
            }
        }
        //  else {
        //   window.location.href = newURL;
        // }

        }, 300);
      }
    };

    // 메시지 이벤트 리스너 등록
    window.addEventListener('message', handleMessage);


    /***************************************************************************************
     * 카카오 로그인 연동
    ***************************************************************************************/
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // 개발 서버
      if( window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('sajuin.o2oz.net') > -1 ) {
        window.Kakao.init('c21cb8beaa899df0dc2dd968efeb2a49');
      } else {
        window.Kakao.init('d2baa4ea452166ed952b47db69e28ade');
      }
    };

    /***************************************************************************************
     * 컴포넌트 언마운트 시 이벤트 리스너 제거
    ***************************************************************************************/
    return () => {
      // 네이버 콜백 창 연동 제거
      window.removeEventListener('message', handleMessage);

      // 추가된 카카오 스크립트 제거
      document.body.removeChild(script);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, Location]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="member-home">
        <h1 className="page-title login-title">로그인</h1>

        {(queryString?.isCheck ?? '') === '' &&
          <h2 className="page-subtitle">해당 서비스를 이용하시려면 로그인이 필요합니다.</h2>
        }

        <div className="login-wrap">
          <Button
            variant="outlined"
            className="login-btn"
            onClick={() => handleNaverLogin()}
          >
            <span className="icon1 naver db"></span>
            <span className="login-btn-text">네이버로 로그인</span>
          </Button>
          <Button
            variant="outlined"
            className="login-btn"
            onClick={handleKakaoLogin}
          >
            <span className="icon1 kakao"></span>
            <span className="login-btn-text">카카오로 로그인</span>
          </Button>

          <Button
            variant="outlined"
            className="login-btn"
            onClick={handleGoogleLogin}
          >
            <span className="icon1 google"></span>
            <span className="login-btn-text">구글로 로그인</span>
          </Button>

          <section className="facebook-div">
            {facebookAppId !== '' &&
              <FacebookProvider
                appId={facebookAppId}
              >
                <LoginButton
                  scope="email"
                  onResponse={handleResponse}
                  onError={(error) => console.error(error)}
                >
                  <Button
                    variant="outlined"
                    className="login-btn"
                  >
                    <span className="icon1 facebook"></span>
                    <span className="login-btn-text">페이스북으로 로그인</span>
                  </Button>
                </LoginButton>
              </FacebookProvider>
            }
          </section>

          <Button
            variant="outlined"
            className="login-btn"
            onClick={() => {
              let uri = '?returnUrl=' + encodeURIComponent(queryString?.returnUrl ?? '/');
              navigate("/login/email" + uri);
            }}
          >
            <span className="icon1 email"></span>
            <span className="login-btn-text">이메일로 로그인</span>
          </Button>

          <NavLink to="/qna" className="info-txt">로그인이 안되시나요? &gt;&gt;</NavLink>
        </div>
      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Login;